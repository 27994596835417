import { QueryParam } from "@/constants";
import { useSearchParams } from "react-router-dom";

export const useRoundPrice = () => {
  const [searchParams] = useSearchParams();
  const roundPrice = searchParams.get(QueryParam.RoundPrice) === 'true';

  return {
    roundPrice,
  }
}